import React, { useState } from 'react';
import { useImportCsvMutation } from '../../../services/importApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';

export function ImportPage(props) {
    const [file, setFile] = useState<File | null>(null);
    const [importCsv, importResult] = useImportCsvMutation();
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = () => {
        if (file) {
            const userConfirmed = window.confirm('ユーザーさんがポイント付与されます！本当にこのファイルをアップロードしますか？');
            if (userConfirmed) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('filename', file.name); // Add file name to formData
                importCsv(formData);
                //should unselect file
                setFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the file input
                }
                alert('ポイント付与がリクエストされました。\n付与履歴から結果が確認できます。');

            }
        } else {
            alert('ファイルを選択してください');
        }
    };

    return (
        <Layout title="ポイント付与">
            <ToolBar title="ポイント付与" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <input type="file" onChange={handleFileChange} ref={fileInputRef}/>
            <button onClick={handleUploadClick}>CSVファイルをアップロード</button>
        </Layout>
    );
}

export default ImportPage;